<template>
<!--  香港留学-->
  <div class="overseasStudy">
    <div>
      <template v-if="language=='zh'">
        <myimage :url='url'></myimage>
      </template>
      <template v-if="language=='TW'">
        <myimage :url='urlTW'></myimage>
      </template>
      <template v-if="language=='En'">
        <myimage :url='urlEn'></myimage>
      </template>
    </div>
    <!-- pc端 -->
    <div class="hidden-sm-and-down">
      <div class="content2">
      <div class="title">
        <div class="english-yellow">{{$t('overseasStudy.Advantages')}}</div>
        <div class="service-con">
          <div class="servicetop">{{$t('overseasStudy.留学优势')}}</div>
          <div class="line2"></div>
        </div>
      </div>
      <div class="tabs">
        <div
            :class="tabsindex == index ? 'change' : ''"
            v-for="(item, index) in overseaStudy"
            :key="index"
            @click="tabsclick(index,item.abroadId)"
        >{{item.title}}
<!--          <template v-if="item.title.length<8">{{ item.title }}</template>-->
<!--          <template v-else>{{ item.title.slice(0,6) }}...</template>-->
        </div>
      </div>
      <div class="youshi" style="padding-bottom:20PX">
        <div class="youshi-img">
          <el-image :src="baseUrl+AbroadDetils.image" fit="cover"></el-image>
        </div>
        <div style="width: calc(100% - 500PX)!important">
          <div class="title2">{{AbroadDetils.subTitle}}</div>
          <div class="text">
           {{AbroadDetils.content}}
          </div>
          <div class="btn" @click="contactus">{{$t('overseasStudy.联系我们')}}</div>
        </div>
      </div>
    </div>
    <div class="bgc">
      <div class="content3">
        <div class="title">
          <div class="english-yellow">{{$t('overseasStudy.Study')}}</div>
          <div class="service-con">
            <div class="servicetop2">{{$t('overseasStudy.多种留学方案匹配不同人群')}}</div>
          </div>
        </div>
        <div>
          <template v-if="language=='zh'">
            <img src="../../assets/lxue.png" style="width: 100%" @click="toapply">
          </template>
          <template v-if="language=='TW'">
            <img src="../../assets/lxueTW.png" style="width: 100%" @click="toapply">
          </template>
          <template v-if="language=='En'">
            <img src="../../assets/lxueEn.png" style="width: 100%" @click="toapply">
          </template>
        </div>
<!--        <div class="list">-->
<!--          <div class="card" v-for="(item, index) in list2" :key="index">-->
<!--            <div :style="{ 'background-color': item.color }">-->
<!--              {{ item.title }}-->
<!--            </div>-->
<!--            <template v-if="index==0">-->
<!--               <div class="div">-->
<!--              <div class="text">-->
<!--                <img src="../../assets/image/548.png" style="" alt=""/>-->
<!--                <span>{{$t('overseasStudy.适合人群')}}：</span>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                 <div></div>-->
<!--                 <div>{{$t('overseasStudy.对选校定位及留学申请流程不清楚')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                  <div></div>-->
<!--                  <div>{{$t('overseasStudy.缺少时间精力自行准备申请材料')}}</div>-->
<!--              </div>-->
<!--              <div style="height: 16px"></div>-->
<!--              <div style="height: 16px"></div>-->
<!--                   &lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.没有留学申请经验')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.没有确定申请学校')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.没有确定申请学校')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.英语语言能力不足')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--            </div>-->
<!--            <div class="div">-->
<!--              <div class="text" style="background-color: #fff">-->
<!--                <img src="../../assets/image/967.png" style="" alt=""/>-->
<!--                <span>{{$t('overseasStudy.项目特色')}}：</span>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('overseasStudy.根据学生条件提供定制式留学方案')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('overseasStudy.提供准确的申请时间规划')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('overseasStudy.提供详细全面的申请材料指导')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('overseasStudy.根据相关专业和面试要求提供面试服务和指引材料')}}</div>-->
<!--              </div>-->
<!--&lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.没有留学申请经验')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.没有确定申请学校')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.没有确定申请学校')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.英语语言能力不足')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--            </div>-->
<!--            </template>-->
<!--            <template v-if="index==1">-->
<!--               <div class="div">-->
<!--              <div class="text">-->
<!--                <img src="../../assets/image/548.png" style="" alt=""/>-->
<!--                <span>{{$t('overseasStudy.适合人群')}}：</span>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('overseasStudy.本科院校背景较差')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                 <div></div>-->
<!--                 <div>{{$t('overseasStudy.GPA及语言成绩偏低')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                  <div></div>-->
<!--                  <div>{{$t('overseasStudy.软实力不足')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                  <div></div>-->
<!--                  <div>{{$t('overseasStudy.国内考研失利后想入读名校')}}</div>-->
<!--              </div>-->
<!--&lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.想要提升背景')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.想要有专业老师把控整个申请流程')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.想有导师全程规划考试及选课流程')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.除求学外')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--            </div>-->
<!--            <div class="div">-->
<!--              <div class="text" style="background-color: #fff">-->
<!--                <img src="../../assets/image/967.png" style="" alt=""/>-->
<!--                <span>{{$t('overseasStudy.项目特色')}}：</span>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('overseasStudy.从前期咨询到成功入学全程一站式服务收获更高性价比')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('overseasStudy.资深教育界专家顾问')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('overseasStudy.提供独家资源')}}</div>-->
<!--              </div>-->
<!--&lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.留学工作全指导')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.没量身打造独特背景提升增加竞争优势')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.中美两国咨询老师全程把关助力申请')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--              <div style="height: 16px"></div>-->
<!--            </div>-->
<!--            </template>-->
<!--            <template v-if="index==2">-->
<!--               <div class="div">-->
<!--              <div class="text">-->
<!--                <img src="../../assets/image/548.png" style="" alt=""/>-->
<!--                <span>{{$t('overseasStudy.适合人群')}}：</span>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('overseasStudy.有明确的选校定位')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('overseasStudy.主动性较强且有清晰的申请规划')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('overseasStudy.英语阅读及写作能力较强')}}</div>-->
<!--              </div>-->
<!--              <div style="height: 16px"></div>-->
<!--&lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.已完成标准化考试')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.初步确定目标学校')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.无法独立完成文书')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.希望获得专业指导')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--            </div>-->
<!--            <div class="div">-->
<!--              <div class="text" style="background-color: #fff">-->
<!--                <img src="../../assets/image/967.png" style="" alt=""/>-->
<!--                <span>{{$t('overseasStudy.项目特色')}}：</span>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('overseasStudy.针对学生需求提供细分服务')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('overseasStudy.拒绝捆绑式销售')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('overseasStudy.可灵活选择签注指导')}}</div>-->
<!--              </div>-->
<!--&lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.专业咨询老师评价学生背景')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.专业文书老师指导创作思路')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="text2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>{{$t('overseasStudy.可灵活选择签注指导')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--              <div style="height: 16px"></div>-->
<!--            </div>-->
<!--            </template>-->
<!--            <div class="btn" :style="{ 'background-color': item.color }" @click="toapply">-->
<!--              {{ item.btn }}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <div class="content2">
      <div class="title">
        <div class="english-yellow">{{$t('overseasStudy.SERVICE')}}</div>
        <div class="service-con">
          <div class="servicetop">
            {{$t('overseasStudy.科学智能的服务体系')}}
          </div>
          <div class="line2"></div>
        </div>
      </div>
      <div class="fuwu">
        <div :class="language=='En'?'card3':'card3 heightcard3'">
          <div>{{$t('overseasStudy.详细的留学规划')}}</div>
          <div>
            {{$t('overseasStudy.基于企业内部庞大的数据库资料')}}
          </div>
        </div>
        <div :class="language=='En'?'card3':'card3 heightcard3'">
          <div> {{$t('overseasStudy.全方位的背景提升')}}</div>
          <div>
             {{$t('overseasStudy.根据选校定位为学生匹配相关的背景提升项目和机会')}}
          </div>
        </div>
        <div :class="language=='En'?'card3':'card3 heightcard3'">
          <div>{{$t('overseasStudy.优质的文书材料')}}</div>
          <div>
            {{$t('overseasStudy.文书顾问与学生进行头脑风暴')}}
          </div>
        </div>
        <div :class="language=='En'?'card3':'card3 heightcard3'">
          <div>{{$t('overseasStudy.高效的笔试面试特训')}}</div>
          <div>
            {{$t('overseasStudy.HK汇生会拥有丰富的本科')}}
          </div>
        </div>
        <div :class="language=='En'?'card3':'card3 heightcard3'">
          <div> {{$t('overseasStudy.升学后的留学援助')}}</div>
          <div>
            {{$t('overseasStudy.HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题')}}
          </div>
        </div>
      </div>
      <div class="zhunbei">
        <div class="title">
          <div class="english-yellow">{{$t('overseasStudy.Prepare')}}</div>
          <div class="service-con">
            <div class="servicetop">{{$t('overseasStudy.副学士升学途径')}}</div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="shenqin">
          <div class="card">
            <div>
              <div>{{$t('overseasStudy.申请前')}}</div>
              <div>{{$t('overseasStudy.Beforeapplication')}}</div>
            </div>
            <div class="accordion">
              <div class="title2 marbot16" slot="title" @click="tojump(2)">{{$t('overseasStudy.选校定位')}}<img src="@/assets/yrjt.png" alt=""></div>
              <div class="marbot10">{{$t('overseasStudy.选校分析')}}</div>
              <div class="marbot10">{{$t('overseasStudy.方案定位')}}</div>
              <div class="marbot10">{{$t('overseasStudy.留学策略定制')}}</div>
              <div class="title2 marbot16" slot="title" @click="tojump(1)">
                    {{$t('overseasStudy.个月VIP定制私人留学顾问')}}<img src="@/assets/yrjt.png" alt="">
              </div>
              <div class="marbot10">{{$t('overseasStudy.名师实时咨询')}}</div>
              <div class="marbot10">{{$t('overseasStudy.选校方案定位')}}</div>
              <div class="marbot10">{{$t('overseasStudy.申请策略定制')}}</div>
              <div class="marbot10">{{$t('overseasStudy.申请材料指导')}}</div>
              <div class="marbot10">{{$t('overseasStudy.网申课程全覆盖')}}</div>
            </div>
          </div>
          <div class="card">
            <div>
              <div>{{$t('overseasStudy.申请中')}}</div>
              <div>{{$t('overseasStudy.During')}}</div>
            </div>
            <div class="accordion">
              
                <div class="title2 marbot16" slot="title" @click="tojump(6)">{{$t('overseasStudy.文书材料')}}<img src="@/assets/yrjt.png" alt=""></div>
<!--                <div class="marbot10">{{$t('overseasStudy.全套文书')}}</div>-->
                <div class="marbot10">{{$t('overseasStudy.个人陈述')}}</div>
                <div class="marbot10">{{$t('overseasStudy.简历')}}</div>
                <div class="marbot10">{{$t('overseasStudy.推荐信')}}</div>
                <div class="marbot10">{{$t('overseasStudy.其他')}}</div>
<!--                <div class="marbot10">{{$t('overseasStudy.CV')}}</div>-->
<!--                <div class="marbot10">{{$t('overseasStudy.RS')}}</div>-->
<!--                <div class="marbot10">{{$t('overseasStudy.R2')}}</div>-->
<!--                <div class="marbot10">{{$t('overseasStudy.PCE')}}</div>-->
<!--                <div class="marbot10">{{$t('overseasStudy.文书翻译')}}</div>-->
<!--                <div class="marbot10">{{$t('overseasStudy.高定文书')}}</div>-->
<!--                <div class="marbot10">{{$t('overseasStudy.颜色')}}</div>-->
                <div class="title2 marbot16" slot="title" @click="tojump(3)">{{$t('overseasStudy.网申助手')}}<img src="@/assets/yrjt.png" alt=""></div>
<!--                <div class="title2 marbot16" slot="title" @click="tojump()">{{$t('overseasStudy.网申管家')}}</div>-->
                <div class="title2 marbot16" slot="title" @click="tojump(4)">{{$t('overseasStudy.面试指导')}}<img src="@/assets/yrjt.png" alt=""></div>
             
            </div>
          </div>
          <div class="card">
            <div>
              <div>{{$t('overseasStudy.申请后')}}</div>
              <div>{{$t('overseasStudy.After')}}</div>
            </div>
            <div class="accordion">
                <div class="title2 marbot16" slot="title" @click="tojump(5)">{{$t('overseasStudy.签证指导')}}<img src="@/assets/yrjt.png" alt=""></div>
                <div class="title2 marbot16" slot="title">{{$t('overseasStudy.落地服务')}}</div>
                <div class="title2 marbot16" slot="title">{{$t('overseasStudy.海外租房')}}</div>
                <div class="title2 marbot16" slot="title">{{$t('overseasStudy.留学迎新礼包')}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="liucheng">
        <div class="title">
          <div class="english-yellow">{{$t('overseasStudy.PROCESS')}}</div>
          <div class="service-con">
            <div class="servicetop">
              {{$t('overseasStudy.汇生汇留学服务')}}
            </div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="img">
          <template v-if="language=='zh'">
            <img src="../../assets/image/liuchengtu.png" alt=""/>
          </template>
          <template v-if="language=='TW'">
            <img src="../../assets/image/liuchengtuTW.png" alt=""/>
          </template>
          <template v-if="language=='En'">
            <img src="../../assets/image/liuchengtuEn.png" alt=""/>
          </template>
        </div>
      </div>
    </div>
    <div class="bgc">
      <div class="content3">
        <div class="title">
          <div class="english-yellow">{{$t('overseasStudy.ADVANTAGE')}}</div>
          <div class="service-con">
            <div class="servicetop2">{{$t('overseasStudy.选择汇生会')}}</div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="famous-bot-hk">
          <div>
            <div>
              <img src="../../assets/niu.png" alt=""/>
            </div>
            <div class="famous-bot-hkright">
              <div class="famous-bot-hktitle">{{$t('overseasStudy.香港本土高端留学机')}}</div>
              <div>
                <div>{{$t('overseasStudy.年高端申请服务经验')}}</div>
                <div>{{$t('overseasStudy.优质的定制化文书材料')}}</div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <img src="../../assets/image/famous/h2.png" alt=""/>
            </div>
            <div class="famous-bot-hkright">
              <div class="famous-bot-hktitle">{{$t('overseasStudy.一站式服务流程')}}</div>
              <div>
                <div>{{$t('overseasStudy.专业资深导师团队')}}</div>
                <div>{{$t('overseasStudy.包括香港本地教授助阵')}}</div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <img src="../../assets/image/famous/h3.png" alt=""/>
            </div>
            <div class="famous-bot-hkright">
              <div class="famous-bot-hktitle">{{$t('overseasStudy.海量成功案例')}}</div>
              <div>
                <div>{{$t('overseasStudy.录取成功率')}}</div>
                <div>{{$t('overseasStudy.录取率')}}</div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <img src="../../assets/image/famous/h4.png" alt=""/>
            </div>
            <div class="famous-bot-hkright">
              <div class="famous-bot-hktitle">{{$t('overseasStudy.一站式留学服务')}}</div>
              <div>{{$t('overseasStudy.提供完善的接机')}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="bgc">-->
<!--      <div class="content3">-->
<!--        <div class="title">-->
<!--          <div class="english-yellow">{{$t('overseasStudy.SUCCESSOFFER')}}</div>-->
<!--          <div class="service-con">-->
<!--            <div class="servicetop2">{{$t('overseasStudy.录取捷报')}}</div>-->
<!--            <div class="line2"></div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="success-offer">-->
<!--          <div class="" v-for="(item, index) in Admission" :key="index">-->
<!--            <div class="success-offerleft">-->
<!--               <img :src="baseUrl+item.image" alt="">-->
<!--            </div>-->
<!--            <div class="success-offerright">-->
<!--              <div>{{$t('overseasStudy.学生姓名')}}：{{item.studentName}}</div>-->
<!--              <div>{{$t('overseasStudy.申请类型')}}：{{item.applyType}}</div>-->
<!--              <div>{{$t('overseasStudy.学术成绩')}}：{{item.academicRecord}}</div>-->
<!--              <div>{{$t('overseasStudy.英语成绩')}}：{{item.englishResults}}</div>-->
<!--              <div>{{$t('overseasStudy.录取大学')}}：{{item.admissionSchool}}</div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="btn"  @click="moreclick">{{$t('overseasStudy.查看更多')}}</div>-->
<!--        <div style="height: 70px"></div>-->
<!--      </div>-->
<!--    </div>-->
      <div class="content2">
        <div class="title">
          <div class="english-yellow">{{$t('overseasStudy.SUCCESSOFFER')}}</div>
          <div class="service-con">
            <div class="servicetop">
              {{$t('overseasStudy.录取捷报')}}
            </div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="successoffer">
          <div v-for="(item,index) in Admission" :key='index'>
            <div class="successoffer-left">
              <img :src="baseUrl+item.image" alt="">
            </div>
            <div class="successoffer-right">
              <div> {{$t('overseasStudy.学生姓名')}}：{{item.studentName}}</div>
              <div> {{$t('overseasStudy.申请类型')}}：{{item.applyType}}</div>
              <div> {{$t('overseasStudy.学术成绩')}}：{{item.academicRecord}}</div>
              <div> {{$t('overseasStudy.英语成绩')}}：{{item.englishResults}}</div>
              <div> {{$t('overseasStudy.录取大学')}}：{{item.admissionSchool}}</div>
            </div>
          </div>
        </div>
        <div class="btn"  @click="moreclick">{{$t('overseasStudy.查看更多')}}</div>
      </div>
    </div>
    <!-- 移动端 -->
    <div class="hidden-sm-and-up">
      <div class="content2-sm">
        <div class="admin-list-choose">
          <div v-for="(item,index) in lefttype" :key='index' @click="toleftindex(index)"  :class="leftindex===index?'leftactive':''">{{item}}</div>
        </div>
        <div class="title">
          <div class="english-yellow">{{$t('overseasStudy.Advantages')}}</div>
          <div class="service-con">
            <div class="servicetop">{{$t('overseasStudy.留学优势')}}</div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="tabs">
          <div
              :class="tabsindex == index ? 'change' : ''"
               v-for="(item, index) in overseaStudy"
            :key="index"
            @click="tabsclick(index,item.abroadId)"
          >{{item.subTitle}}
<!--            <template v-if="item.title.length<5">{{ item.title }}</template>-->
<!--            <template v-else>{{ item.title.slice(0,4) }}...</template>-->

          </div>
        </div>
        <div class="youshi" >
          <div class="youshi-img">
            <el-image :src="baseUrl+AbroadDetils.image" fit="cover"></el-image>
          </div>
          <div >
            <div class="title2">{{AbroadDetils.title}}</div>
            <div class="text" >
              {{AbroadDetils.content}}
            </div>
            <div class="btn" @click="contactus">{{$t('overseasStudy.联系我们')}}</div>
          </div>
        </div>
      </div>
      <div class="bgc-sm">
        <div class="content3">
          <div class="title">
            <div class="english-yellow">{{$t('overseasStudy.Study')}}</div>
            <div class="service-con">
              <div class="servicetop2">{{$t('overseasStudy.选择适合你的留学计划')}}</div>
            <div class="line2"></div>
            </div>
          </div>
          <div class="list-sm">
           <template v-if="language=='zh'">
             <div class="list-abroadsm" @click="toapply"><img src="@/assets/phone/overseasStudy/plan1.png" alt=""></div>
             <div class="list-abroadsm" @click="toapply"><img src="@/assets/phone/overseasStudy/plan2.png" alt=""></div>
             <div class="list-abroadsm" @click="toapply"><img src="@/assets/phone/overseasStudy/plan4.png" alt=""></div>
           </template>
            <template v-if="language=='TW'">
              <div class="list-abroadsm" @click="toapply"><img src="@/assets/phone/overseasStudy/plan1TW.png" alt=""></div>
              <div class="list-abroadsm" @click="toapply"><img src="@/assets/phone/overseasStudy/plan2TW.png" alt=""></div>
              <div class="list-abroadsm" @click="toapply"><img src="@/assets/phone/overseasStudy/plan4TW.png" alt=""></div>
            </template>
            <template v-if="language=='En'">
              <div class="list-abroadsm" @click="toapply"><img src="@/assets/phone/overseasStudy/plan1En.png" alt="" style="height: 596px"></div>
              <div class="list-abroadsm" @click="toapply"><img src="@/assets/phone/overseasStudy/plan2En.png" alt="" style="height: 672px"></div>
              <div class="list-abroadsm" @click="toapply"><img src="@/assets/phone/overseasStudy/plan4En.png" alt="" style="height: 554px"></div>
            </template>
          </div>
        </div>
      </div>
      <div class="content2-sm">
        <div class="title" style="margin-bottom: 35px">
          <div class="english-yellow">{{$t('overseasStudy.SERVICE')}}</div>
          <div class="service-con">
            <div class="servicetop">
              {{$t('overseasStudy.科学智能的服务体系')}}
            </div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="service-plan ">
            <div :class="language=='En'?'serviceplanheight2':'serviceplanheight'">
              <div class="service-plantitle">{{$t('overseasStudy.详细的留学规划')}}</div>
              <div class="planline"></div>
              <div class="service-plancon">
               {{$t('overseasStudy.基于企业内部庞大的数据库资料')}}
              </div>
            </div>        
            <div :class="language=='En'?'serviceplanheight2':'serviceplanheight'">
            <div class="service-plantitle"> {{$t('overseasStudy.全方位的背景提升')}}</div>
              <div class="planline"></div>
              <div class="service-plancon">
                 {{$t('overseasStudy.根据选校定位为学生匹配相关的背景提升项目和机会')}}
              </div>
            </div>              
            <div :class="language=='En'?'serviceplanheight2':'serviceplanheight'">
              <div class="service-plantitle">{{$t('overseasStudy.优质的文书材料')}}</div>
              <div class="planline"></div>
              <div class="service-plancon">
                {{$t('overseasStudy.文书顾问与学生进行头脑风暴')}}
              </div>
            </div>            
            <div :class="language=='En'?'serviceplanheight2':'serviceplanheight'">
              <div class="service-plantitle">{{$t('overseasStudy.高效的笔试面试特训')}}</div>
              <div class="planline"></div>
              <div class="service-plancon">
                     {{$t('overseasStudy.HK汇生会拥有丰富的本科')}}
              </div>
            </div>            
            <div :class="language=='En'?'serviceplanheight2':'serviceplanheight'">
              <div class="service-plantitle">{{$t('overseasStudy.升学后的留学援助')}}</div>
              <div class="planline"></div>
              <div class="service-plancon">
                {{$t('overseasStudy.HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题')}}
              </div>
          </div>  
      </div>
      <div class="zhunbei-sm">
        <div class="title">
          <div class="english-yellow">{{$t('overseasStudy.Prepare')}}</div>
          <div class="service-con">
            <div class="servicetop">{{$t('overseasStudy.准备信息')}}</div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="zhunbeimsg">
           <div class=" zhunbeitwo" style="margin-bottom:15PX">
             <!-- <img src="@/assets/phone/overseasStudy/msg.png" alt=""> -->
             <div class="card">
              <div>
                <div>{{$t('overseasStudy.申请前')}}</div>
                <div>{{$t('overseasStudy.Beforeapplication')}}</div>
              </div>
              <div class="accordion">
              <div class="title2 marbot16" slot="title" @click="tojump(2)">{{$t('overseasStudy.选校定位')}}<img src="@/assets/yrjt.png" alt=""></div>
              <div class="marbot10">{{$t('overseasStudy.选校分析')}}</div>
              <div class="marbot10">{{$t('overseasStudy.方案定位')}}</div>
              <div class="marbot10">{{$t('overseasStudy.留学策略定制')}}</div>
              <div class="title2 marbot16" slot="title" @click="tojump(1)">
                    {{$t('overseasStudy.个月VIP定制私人留学顾问')}}<img src="@/assets/yrjt.png" alt="">
              </div>
              <div class="marbot10">{{$t('overseasStudy.名师实时咨询')}}</div>
              <div class="marbot10">{{$t('overseasStudy.选校方案定位')}}</div>
              <div class="marbot10">{{$t('overseasStudy.申请策略定制')}}</div>
              <div class="marbot10">{{$t('overseasStudy.申请材料指导')}}</div>
              <div class="marbot10">{{$t('overseasStudy.网申课程全覆盖')}}</div>
            </div>
            </div>
           </div>
           <div class="zhunbeitwo">
            <div class="card">
              <div>
                 <div>{{$t('overseasStudy.申请中')}}</div>
                 <div>{{$t('overseasStudy.During')}}</div>
              </div>
              <div class="accordion">
                <div class="title2 marbot16" slot="title" @click="tojump(6)">{{$t('overseasStudy.文书材料')}}<img src="@/assets/yrjt.png" alt=""></div>
<!--                <div class="marbot10">{{$t('overseasStudy.全套文书')}}</div>-->
<!--                <div class="marbot10">{{$t('overseasStudy.CV')}}</div>-->
<!--                <div class="marbot10">{{$t('overseasStudy.RS')}}</div>-->
<!--                <div class="marbot10">{{$t('overseasStudy.R2')}}</div>-->
<!--                <div class="marbot10">{{$t('overseasStudy.PCE')}}</div>-->
<!--                <div class="marbot10">{{$t('overseasStudy.文书翻译')}}</div>-->
<!--                <div class="marbot10">{{$t('overseasStudy.高定文书')}}</div>-->
<!--                <div class="marbot10">{{$t('overseasStudy.颜色')}}</div>-->
                  <div class="marbot10">{{$t('overseasStudy.个人陈述')}}</div>
                  <div class="marbot10">{{$t('overseasStudy.简历')}}</div>
                  <div class="marbot10">{{$t('overseasStudy.推荐信')}}</div>
                  <div class="marbot10">{{$t('overseasStudy.其他')}}</div>
                <div class="title2 marbot16" slot="title" @click="tojump(3)">{{$t('overseasStudy.网申助手')}}<img src="@/assets/yrjt.png" alt=""></div>
<!--                <div class="title2 marbot16" slot="title">{{$t('overseasStudy.网申管家')}}</div>-->
                <div class="title2 marbot16" slot="title"  @click="tojump(4)">{{$t('overseasStudy.面试指导')}}<img src="@/assets/yrjt.png" alt=""></div>
              </div>
            </div>
           </div>
           <div class="zhunbeithree">
             <div class="card2">
                <div>
                   <div>{{$t('overseasStudy.申请后')}}</div>
                   <div>{{$t('overseasStudy.After')}}</div>
                </div>
                <div class="accordion2">
                  <div class="title2 marbot16" slot="title"  @click="tojump(5)">{{$t('overseasStudy.签证指导')}}<img src="@/assets/yrjt.png" alt=""></div>
                  <div class="title2 marbot16" slot="title">{{$t('overseasStudy.落地服务')}}</div>
                  <div class="title2 marbot16" slot="title">{{$t('overseasStudy.海外租房')}}</div>
                  <div class="title2 marbot16" slot="title">{{$t('overseasStudy.留学迎新礼包')}}</div>
                </div>
             </div>
           </div>
        </div>
      </div>
      <div class="content2-sm">
        <div class="title" style="margin-bottom: 35px">
          <div class="english-yellow">{{$t('overseasStudy.PROCESS')}}</div>
          <div class="service-con">
            <div class="servicetop">
              {{$t('overseasStudy.汇生汇留学服务')}}
            </div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="serve-process">
          <template v-if="language=='zh'">
            <img src="../../assets/image/liuchengtu.png" alt="">
          </template>
          <template v-if="language=='TW'">
            <img src="../../assets/image/liuchengtuTW.png" alt="">
          </template>
          <template v-if="language=='En'">
            <img src="../../assets/image/liuchengtuEn.png" alt="">
          </template>

        </div>
      </div>
      <div class="bgc-sm">
        <div class="content3" >
          <div class="title" style="margin-bottom: 30px;">
            <div class="english-yellow">{{$t('overseasStudy.ADVANTAGE')}}</div>
            <div class="service-con">
              <div class="servicetop3">{{$t('overseasStudy.选择汇生汇')}}</div>
              <div class="line2"></div>
            </div>
          </div>
          <div class="famous-bot-hk">
            <template v-if="language=='zh'">
              <img src="@/assets/phone/overseasStudy/zy.png" alt="">
            </template>
            <template v-if="language=='TW'">
              <img src="@/assets/phone/overseasStudy/zyTW.png" alt="">
            </template>
            <template v-if="language=='En'">
              <img src="@/assets/phone/overseasStudy/zyEn.png" alt="">
            </template>
          </div>
              
        </div>
        </div>
      </div>
      <div class="content2-sm">
        <div class="title">
          <div class="english-yellow">{{$t('overseasStudy.SUCCESSOFFER')}}</div>
          <div class="service-con">
            <div class="servicetop">{{$t('overseasStudy.录取捷报')}}</div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="success-offer-sm">
          <div class="" v-for="(item, index) in Admission" :key="index">
            <div class="success-offerleft-sm">
              <img :src="baseUrl+item.image" alt="">
            </div>
            <div class="success-offerright-sm">
              <div>{{$t('overseasStudy.学生姓名')}}：{{item.studentName}}</div>
              <div>{{$t('overseasStudy.申请类型')}}：{{item.applyType}}</div>
              <div>{{$t('overseasStudy.学术成绩')}}：{{item.academicRecord}}</div>
              <div>{{$t('overseasStudy.英语成绩')}}：{{item.englishResults}}</div>
              <div>{{$t('overseasStudy.录取大学')}}：{{item.admissionSchool}}</div>
            </div>
          </div>
        </div>
        <div class="lqbtn" @click="moreclick">{{$t('overseasStudy.查看更多')}}</div>
      </div> 
    <div class="content2-sm">
    <div class="" :style="[{'height':companyheight2}]" >
          <div class="title">
            <div class="english-yellow">{{$t('overseasStudy.APPLICATION')}}</div>
            <div class="service-con">
              <div class="servicetop">{{$t('overseasStudy.支持')}}</div>
              <div class="line2"></div>
            </div>
          </div>
          <div class="service-con-sm">
            <div class="all-service-company-sm" >
              <div v-for="(item,index) in agencList" :key='index' :style="[{'margin-right':companymargin}]" >
                <img :src="item.thumbnail" alt=""  >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mydialog ref="dialog" @sumbit='sumbit'></mydialog>
    <erweimaDialog ref="ewmdialog"></erweimaDialog>
    <Myicon @iconchange='iconchange'></Myicon>
  </div>
</template>

<script>
import myimage from '@/components/myimage'
import mydialog from '@/components/dialog'
import erweimaDialog from '@/components/erweimaDialog'
import Myicon from '@/components/mainicon'
import {hongKongStudyAbroadList,hongKongStudyAbroadDetils,agencList,addMessageRecord} from '@/api/index.js'
export default {
  components: {
    myimage,
    mydialog,
    erweimaDialog,
    Myicon
  },
  data() {
    return {
      url:require('../../assets/phone/tu1.png'),
      urlTW:require('../../assets/phone/tu1TW.png'),
      urlEn:require('../../assets/phone/tu1En.png'),
      src: "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
      list: ["优势一", "优势二", "优势三", "优势四", "优势五"],
      
      tabsindex: 0,
      leftindex: '',
      Abroadquery:{
        category:1,//	必选	String	1:留学优势2:专家团队3:港漂干货4:录取捷报
        type:1,//	必选	String	1:留学优势-香港留学2:留学优势-副学士3:留学优势-本科4:留学优势-研究生5:录取捷报-副学士6:录取捷报-本科7:录取捷报-研究生
      },
      overseaStudy:[],//留学优势-香港留学
      Admission:[],//录取捷报-副学士
      AbroadDetils:{},//香港留学详情
      baseUrl:'',
      agencList:[],//合作伙伴list
      language:'zh',//默认中文
    };
  },
  computed:{
    companyheight(){
      if(this.agencList.length>0){
     return  Math.ceil(this.agencList.length/4)*110+200 +'PX'
      }else{
         return 300+'PX'
      }
    },
     companyheight2(){
      if(this.agencList.length>0){
        return (Math.ceil(this.agencList.length/Math.floor( 335/100))*110+80)+'PX'
      }else{
         return 100+'PX'
      }
    },
    companymargin2(){
       if(this.agencList.length>0){
        return Math.floor((this.screenWidth*0.9-3*224)/2)+'PX'
      }else{
         return 0+'PX'
      }
    },
    companymargin(){
      if(this.agencList.length>0){
        return Math.floor(( this.screenWidth*0.9-Math.floor(this.screenWidth*0.9/224)*224)/(Math.floor(this.screenWidth*0.9/224)-1))+'PX'
      }else{
         return 0+'PX'
      }
    },
    list2(){
      return [
        {
          title: this.$t('overseasStudy.全套申请计划'),
          btn: this.$t('overseasStudy.留学咨询'),
          color: "#4486e6",
        },
        {
          title: this.$t('overseasStudy.直通车申请计划'),
          btn: this.$t('overseasStudy.留学咨询'),
          color: "#F78A13",
        },
        {
          title:this.$t('overseasStudy.半DIY申请计划') ,
          btn: this.$t('overseasStudy.留学咨询'),
          color: "#68D26E",
        },
      ]
    } ,

    lefttype(){
      return  [
          this.$t('overseasStudy.副学士'),
        this.$t('overseasStudy.本科'),
        this.$t('overseasStudy.研究生')
      ]
    },
  },
  mounted() {
    console.log(this.list2[0].title);
    this.baseUrl=this.$store.state.baseUrl
    this.language=localStorage.getItem('language')
    this._hongKongStudyAbroadList(1,1)
    this._hongKongStudyAbroadList(4,5)
    this._agencList()
  },
  methods: {
    tojump(e){
      if(e==1){
        window.location.href='https://m.tb.cn/h.ftIjQEB?tk=5tdU2PtX7Gx'
      }
      if(e==2){
        window.location.href='https://m.tb.cn/h.fGIDLZ4?tk=ktuh2Pt1RmA'
      }
      if(e==3){
        window.location.href='https://m.tb.cn/h.fHIF6y8?tk=77ko2PtXgVE'
      }
      if(e==4){
        window.location.href='https://m.tb.cn/h.fHIF1rD?tk=6jZJ2PtXoPJ'
      }
      if(e==5){
        window.location.href='https://m.tb.cn/h.fGICFf4?tk=YXE62PtWKri'
      }
      if(e==6){
        window.location.href='https://m.tb.cn/h.fGIBNEi?tk=wXfx2Pt3JIP'
      }
    },
    //在线咨询(香港留学)
    sumbit(e){
        e.category=3
      addMessageRecord(e).then(()=>{
        this.$message.success(this.$t('admission.已提交'))
      })
    },
      _agencList(){
        console.log('222')
        agencList().then(res=>{
          console.log(res)
          res.data.forEach((item)=>{
            item.thumbnail=this.baseUrl+item.thumbnail
          })
          this.agencList=res.data
        })
      },
    _hongKongStudyAbroadList(category,type){
      hongKongStudyAbroadList({
        category:category,
        type:type,
      }).then(res=>{
        console.log(res)
        if(category==1){
          this.overseaStudy=res.data
          this._hongKongStudyAbroadDetils(res.data[0].abroadId)
        }
        if(category==4){
          this.Admission=res.data
          this.Admission = this.Admission.reverse()
        }
      })
    },
    iconchange(){
      this.$refs.dialog.dialogVisible=true
    },
    toapply(){
      this.$refs.ewmdialog.dialogVisibletwo=true
    },
    moreclick() {
      this.$router.push({
        path: '/Admission',
        query: {
          name: "Admission",
          twoindex: 0
        }
      });
    },
    contactus(){
      this.$refs.ewmdialog.dialogVisibletwo= true;
    },
    toleftindex(e) {
      this.leftindex = e
      
      if(e==0){//副学士
         this.$router.push({
          path: '/AssoStudy'
        });
      }
      if(e==1){//本科
         this.$router.push({
          path: '/undergraduateStudy'
        });
      }
      if(e==2){//研究生
         this.$router.push({
          path: '/gra-stuphone'
        });
      }
    },
    tabsclick(index,abroadId) {
      this.tabsindex = index;
      this._hongKongStudyAbroadDetils(abroadId)
      
    },
    _hongKongStudyAbroadDetils(abroadId){
      hongKongStudyAbroadDetils({
        abroadId:abroadId
      }).then(res=>{
        this.AbroadDetils=res.data
      })
    }
  },
};
</script>

<style lang="scss">
.overseasStudy {
  .marbot16{
    margin-bottom: 16PX;
  }
  .marbot10{
    margin-bottom: 10PX;
  }
  .bgc {
    width: 100%;
    background-color: #f7f7f7;

    .content3 {
      width: 1176px;
      margin: 0 auto;
      padding-top: 1px;

      .btn {
        width: 234px;
        height: 70px;
        border-radius: 35px;
        line-height: 70px;
        text-align: center;
        background-color: #fe992b;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        bottom: 5px;
        margin: 0 auto;
      }

      .success-offer {
        margin-top: 75px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // justify-content: space-around;
      }

      .success-offer > div {
        padding: 25px 20px 25px 20px;
        background: #fff;
        width: 370px;
        height: 340px;
        display: flex;
        flex-direction: row;
        // padding-bottom: 80px;
        margin-bottom: 33px;
        margin-right: 20px;
      }

      .success-offerleft {
        width: 50%;
        height: 100%;
        margin-right: 15px;
      }
      .success-offerleft img{
        width: 100%;
        height: 100%;
        margin-right: 15px;
      }

      .success-offerright {
        width: 50%;
        line-height: 26px;
        font-size: 16px;
        white-space: pre-line;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #333;
      }

      .success-offerright span {
        color: #fe992b;
      }

      .famous-bot-hk {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0px 0 24px 0;
      }

      .famous-bot-hk > div {
        width: 45%;
        height: 164px;
        border-radius: 12px;
        background: #fff;
        margin-bottom: 24px;
        display: flex;
        flex-direction: row;
        font-size: 16px;
        text-align: left;
      }

      .famous-bot-hk > div img {
        width: 124px;
        height: 124px;
        margin: 20px 24px 0 20px;
      }

      .famous-bot-hkright {
        padding: 32px 0;
      }

      .famous-bot-hktitle {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 17px;
      }

      .list {
        display: flex;
        justify-content: space-around;
        padding-bottom: 70px;

        .card {
          width: 370px;
          height: 595px;
          border-radius: 20px;
          background-color: #fff;

          .btn {
            width: 312px;
            height: 48px;
            // background-color: #4486e6;
            border-radius: 12px;
            color: #fff;
            font-size: 20px;
            line-height: 48px;
            text-align: center;
            margin: 0 auto;
            margin-top: 50px;
            margin-bottom: 20PX;
          }

          > .div {
            text-align: left;
            padding-left: 24px;
            padding-right: 24px;
            margin-top: 20px;

            .text2 {
              display: flex;
              align-items: center;
              font-size: 15px;
              margin-bottom: 10px;

              > div:nth-child(1) {
                background-color: #333;
                width: 6px;
                height: 6px;
                border-radius: 6px;
                margin-right: 8px;
              }
            }

            .text {
              margin-bottom: 17px;

              > img {
                height: 28px;
                width: 28px;
                vertical-align: middle;
                margin-right: 20px;
                margin-top: -5px;
              }

              > span {
                font-size: 22px;
              }
            }
          }

          > div:nth-child(1) {
            height: 88px;
            border-radius: 20px 20px 0 0;
            // background-color: #4486e6;
            line-height: 88px;
            font-size: 24px;
            color: #fff;
            text-align: center;
            font-weight: 600;
          }
        }
      }

      .title {
        position: relative;
        height: 150px;
        margin-top: 50px;

        .english-yellow {
          color: #fe992b;
          font-size: 44px;
          opacity: 0.3;
        }

        .service-con {
          width: 100%;
          position: absolute;
          top: 17px;
        }

        .line2 {
          width: 124px;
          height: 6px;
          border-radius: 3px;
          background-color: #fe992b;
          margin: 0 auto;
        }

        .servicetop2 {
          // width: 320px;
          font-size: 32px;
          color: #333;
          font-weight: bold;
          padding: 10px 0;
          // border-bottom: 6px solid #fe992b;
          border-radius: 3px;
          margin: 0 auto;
        }
      }
    }
  }

  .content2 {
    width: 1176px;
    margin: 0 auto;
    .btn {
      width: 234px;
      height: 70px;
      border-radius: 35px;
      line-height: 70px;
      text-align: center;
      background-color: #fe992b;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      bottom: 5px;
      margin: 0 auto 50PX auto;
    }
    .successoffer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      > div {
        width: 574px;
        height: 360px;
        display: flex;
        flex-direction: row;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        margin-bottom: 28px;
        padding: 37px 30px;

        .successoffer-left {
          width: 216px;
          height: 286px;
          background: #F7F7F7;
          margin-right: 24px;
        }
        .successoffer-left img{
          width: 216px;
          height: 286px;
        }
        .successoffer-right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: left;
          font-size: 24px;
          font-weight: 400;
          color: #333333;
        }
      }

    }
    .NEWS-btn {
      width: 234px;
      height: 70px;
      border-radius: 35px;
      line-height: 70px;
      text-align: center;
      background-color: #fe992b;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      margin: 0 auto;
      margin-top: 50px;
      margin-bottom: 70px;
    }

    .NEWS {
      display: flex;

      .NEWS-list {
        > div {
          font-size: 24px;
          margin: 55px 0;

          > span {
            color: #333;
          }

          img {
            width: 24px;
            height: 24px;
            vertical-align: middle;
            margin-right: 10px;
          }
        }
      }

      .img {
        margin-right: 50px;

        img {
          width: 684px;
          height: 500px;
        }
      }
    }

    .Expert-team {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      > div {
        width: 240px;
        margin-bottom: 50px;

        > div:nth-child(1) {
          > img {
            width: 200px;
            height: 200px;
            border-radius: 50%;
          }
        }

        > div:nth-child(2) {
          font-size: 28px;
          font-weight: 600;
          margin-top: 26px;
        }

        > div:nth-child(3) {
          font-size: 20px;
          margin-top: 10px;
        }
      }
    }

    .liucheng {
      margin-bottom: 70px;

      .img {
        > img {
          width: 1176px;
          height: 448px;
        }
      }
    }

    .zhunbei {
      padding-top: 60px;

      .shenqin {
        display: flex;
        justify-content: space-around;
        margin-bottom: 145px;

        .accordion {
          padding: 30px;
          font-size: 16PX;
          .el-collapse-item__content {
            color: #666;
            font-size: 16px;
          }

          .title2 {
            font-size: 18PX;
            font-weight: 600;
            display: flex;
            align-items: center;
            img{
              width: 24PX;
              height: 24PX;
              margin-left: 6PX;
            }
          }

          .el-collapse-item__header {
            border: none;
          }

          .el-collapse {
            border: none;
          }

          .el-collapse-item__arrow {
            color: #fe992b;
          }

          .el-collapse-item__wrap {
            border: none;
          }
        }

        .card {
          text-align: left;
          width: 340px;
          min-height: 518px;
          box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);

          > div:nth-child(1) {
            width: 340px;
            height: 110px;
            background-image: url(../../assets/image/846.png);
            font-size: 24px;
            color: #fff;
            padding: 22px 30px 0 30px;

            > div {
              line-height: 32px;
            }
          }
        }
      }
    }

    .fuwu {
      display: flex;
      justify-content: space-around;
      .heightcard3{
        height: 354px;
      }
      .card3 {
        width: 230px;

        border-radius: 12px;
        background-color: #f7f7f7;
        color: rgb(0, 0, 0);
        padding: 50px 27px 0px 27px;
        text-align: left;
        transition: all 0.5s;

        > div:nth-child(1) {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 24px;
        }

        > div:nth-child(2) {
          font-size: 16px;
          line-height: 28px;
        }
      }

      .card3:hover {
        background-color: #fe992b;
        color: rgb(255, 255, 255);
      }
    }

    .youshi {
      display: flex;
      text-align: left;
      margin-bottom: 66PX;
     .youshi-img {
        margin-right: 36px;
        width: 456PX;
        height: 380PX;

        > img {
          width: 456PX;
          height: 352PX;
        }
      }

      div:nth-child(2) {
        position: relative;
        .btn {
          //position: absolute;
          width: 234px;
          height: 70px;
          border-radius: 35px;
          line-height: 70px;
          text-align: center;
          background-color: #fe992b;
          color: #fff;
          font-size: 20px;
          font-weight: 600;
          margin-top: 20PX;
          //bottom: 5px;
        }

        .text {
          font-size: 18px;
          line-height: 38px;
          // margin-bottom: 40px;
        }

        .title2 {
          color: #333;
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 20px;
        }
      }
    }

    .tabs {
      width: 1176px;
      border-bottom: 4px solid rgba($color: #fe992b, $alpha: 0.3);
      display: flex;
      //justify-content: space-around;
      justify-content: center;
      margin-bottom: 36px;
      overflow: scroll;
      > div {
        //width: 160PX;
        height: 60px;
        padding: 0 5px;
        border-radius: 8px 8px 0px 0px;
        //line-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #333;
        font-size: 20px;
        // transition: all 0.5s;
      }

      .change {
        background-color: #fe992b;
        color: #fff;
        transition: all 0.5s;
      }
    }

    .title {
      position: relative;
      height: 150px;
      margin-top: 50px;

      .english-yellow {
        color: #fe992b;
        font-size: 44px;
        opacity: 0.3;
      }

      .service-con {
        width: 100%;
        position: absolute;
        top: 17px;
      }

      .line2 {
        width: 124px;
        height: 6px;
        border-radius: 3px;
        background-color: #fe992b;
        margin: 0 auto;
      }

      .servicetop {
        // width: 160px;
        font-size: 32px;
        color: #333;
        font-weight: bold;
        padding: 10px 0;
        // border-bottom: 6px solid #fe992b;
        border-radius: 3px;
        margin: 0 auto;
      }

      .servicetop2 {
        width: 320px;
        font-size: 32px;
        color: #333;
        font-weight: bold;
        padding: 10px 0;
        // border-bottom: 6px solid #fe992b;
        border-radius: 3px;
        margin: 0 auto;
      }
    }
  }
  // 移动
  .content2-sm{
    width: 100vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    
  
  .main-service-bot-sm{
  padding: 30PX 0;
}
.service-con-sm{
  position: relative;
  .all-service-company-sm{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20PX;
  }
}

.all-service-company-sm>div,.all-service-company-sm>div img{
  width:110PX;
  height: 60PX;
  margin-bottom: 20PX;
}
.lqbtn{
  font-size: 14PX;
  background: #fe992b;
  color: #fff;
  text-align: center;
  height: 48PX;
  line-height: 48PX;
  width: 150PX;
  border-radius: 24PX;
}
     .service-plan {
        width: 335PX;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 16PX;
      }
.planline {
  width: 50%;
  height: 10PX;
  // background: linear-gradient(180deg, #FE992B 0%, rgba(254, 153, 43, 0.3) 100%);
  border-radius: 5PX;
  position: absolute;
  top: 40PX;
  text-align: center;
  left: calc(50% - 25%);
}
    .serviceplanheight{
      height: 250PX!important;
    }
    .serviceplanheight2{
      height: 350PX!important;
    }
.service-plan > div {
  width: 162PX;
  padding:0 19PX;
  background: #F7F7F7;
  border-radius: 12PX;
  position: relative;
  margin-bottom: 10PX;
}

.service-plantitle {
  margin: 24PX auto 24PX auto;
  font-size: 16PX;
  font-weight: bold;
  position: relative;
  z-index: 1;
}

.service-plancon {
  text-align: left;
  font-size: 12PX;
}
.service-plan > div:hover{
  background: linear-gradient(134deg, #FCA648 0%, #FE992B 100%);
  cursor: pointer;
  color: #FFF !important;
  transition: all 0.5s;
}

.admin-list-choose{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.admin-list-choose>div{
  width: 30%;
  height: 42PX;
  background: #f7f7f7;
  text-align: center;
  line-height: 42PX;
  font-size: 12PX;
  margin-top: 20PX;
}
.leftactive {
  background: rgba(254, 153, 43, 0.2)!important;
  //font-weight: bold;
}
    >div{
      width: 335PX;
      margin: 0 auto;
    }
    .title {
      position: relative;
      height: 68PX;
      margin-top: 50PX;

      .english-yellow {
        color: #fe992b;
        font-size: 20PX;
        opacity: 0.3;
      }

      .service-con {
        width: 100%;
        position: absolute;
        top: 0;
      }

      .line2 {
        width: 69PX;
        height: 3PX;
        border-radius: 3PX;
        background-color: #fe992b;
        margin: 0 auto;
      }

      .servicetop {
        // width: 160px;
        font-size: 20PX;
        color: #333;
        font-weight: bold;
        padding: 10PX 0;
        // border-bottom: 6PX solid #fe992b;
        border-radius: 3PX;
        margin: 0 auto;
      }

      .servicetop2 {
        width: 320PX;
        font-size: 32PX;
        color: #333;
        font-weight: bold;
        padding: 10PX 0;
        // border-bottom: 6PX solid #fe992b;
        border-radius: 3PX;
        margin: 0 auto;
      }
      
    }
    .tabs {
      border-bottom: 3PX solid rgba($color: #fe992b, $alpha: 0.3);
      display: flex;
      justify-content: center;
      margin-bottom: 36PX;

      > div {
        //width: 70PX;
        padding: 0 5PX;
        height: 24PX;
        border-radius: 8PX 8PX 0px 0px;
        line-height: 24PX;
        text-align: center;
        color: #333;
        font-size: 12PX;
        // transition: all 0.5s;
      }

      .change {
        background-color: #fe992b;
        color: #fff;
        transition: all 0.5s;
      }
    }
    
    .youshi {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-bottom: 66PX;

      .youshi-img {
        // margin-right: 36px;
        width: 335PX;
        //height: 360PX;
        > img {
          width:335PX!important;
          height: 352PX;
        }
      }

      div:nth-child(2) {
        position: relative;

        .btn {
          position: absolute;
          width: 150PX;
          height: 48PX;
          border-radius: 35PX;
          line-height: 48PX;
          text-align: center;
          background-color: #fe992b;
          color: #fff;
          font-size: 16PX;
          font-weight: 600;
          bottom: -50PX;
          left: calc(45vw - 75PX);
        }

        .text {
          width: 335PX;
          font-size: 14PX;
          line-height: 28PX;
        }

        .title2 {
          color: #333;
          font-size: 16PX;
          font-weight: 600;
          margin: 10PX 0;
          font-weight: bold;
        }
      }
    }
    .serve-system{
      img{
        width: 100%;
        height: 799PX;
      }
    }
    .serve-process{
      width: 335PX;
      overflow-x: scroll;
      margin: 30PX auto 16PX auto;
      img{
        height: 448PX;
        width: 1176PX;
      }
    }
    .Expert-team {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      > div {
        width: 128PX;
        margin-bottom: 30PX;

        > div:nth-child(1) {
          > img {
            width:  128PX;
            height:  128PX;
            border-radius: 50%;
          }
        }

        > div:nth-child(2) {
          font-size: 16PX;
          font-weight: 600;
          margin-top: 26px;
        }

        > div:nth-child(3) {
          font-size: 14PX;
          margin-top: 10px;
        }
      }
    }
    
      .success-offer-sm {
        margin-top: 25PX;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
      }

      .success-offer-sm > div {
        padding: 20PX 16PX 40PX 16PX;
        background: #fff;
        width: 335PX;
        //height: 247PX;
        display: flex;
        flex-direction: row;
        align-items: center;
        // padding-bottom: 80px;
        margin-bottom: 16PX;
        box-shadow: 0px 3PX 6PX 1PX rgba(0, 0, 0, 0.16);
      }

      .success-offerleft-sm {
        // background: #333;
        width: 138PX;
        height: 207PX;
        margin-right: 15PX;
      }
      .success-offerleft-sm img {
        width: 138PX;
        height: 207PX;
        margin-right: 15PX;
        object-fit: fit;
      }

      .success-offerright-sm {
        width: 50%;
        line-height: 26PX;
        height: 207PX;
        text-align: left;
        font-size: 14PX;
        white-space: pre-line;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #333;
      }
      

      .btn {
            width: 150PX;
            height: 48PX;
            background-color: #FE992B;
            border-radius: 24PX;
            color: #fff;
            font-size: 16PX;
            line-height: 48PX;
            text-align: center;
            margin: 0 auto;
            margin-top: 50px;
          }
    .NEWS-btn {
      width: 150PX;
      height: 48PX;
      border-radius: 35PX;
      line-height:48PX;
      text-align: center;
      background-color: #fe992b;
      color: #fff;
      font-size: 16PX;
      font-weight: 600;
      margin: 0 auto;
      // margin-top: 50PX;
      margin-bottom: 20PX;
    }

    .NEWS-sm {
      display: flex;
      flex-direction: column;
      .NEWS-img{
        width: 335PX;
        height: 224PX;
      }
      .NEWS-list {
        > div {
          font-size: 16PX;
          margin: 22PX 0;

          > span {
            color: #333;
          }

          img {
            width: 24PX;
            height: 24PX;
            vertical-align: middle;
            margin-right: 10PX;
          }
        }
      }

      .img {
        margin-right: 50px;

        img {
          width: 684px;
          height: 500px;
        }
      }
    }

  }
   .bgc-sm {
    width: 100%!important;
    background-color: #f7f7f7;
  
    .content3 {
      width:335PX;
      margin: 0 auto;
      padding-top: 1PX;

      .btn {
        width: 234PX;
        height: 70PX;
        border-radius: 35PX;
        line-height: 70PX;
        text-align: center;
        background-color: #fe992b;
        color: #fff;
        font-size: 20PX;
        font-weight: 600;
        bottom: 5PX;
        margin: 0 auto;
      }

      .famous-bot-hk{
        padding: 24PX 0;
        img{
          width: 335PX;
          height: 446PX;
        }
      }
     .list-sm {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 20PX;
        padding-top: 20PX;
       .list-abroadsm img{
          width: 100%;
          height: 522PX;
          margin-top: 20PX;
        }
      }

      .title {
        position: relative;
        height: 68PX;
        margin-top: 50PX;

        .english-yellow {
          color: #fe992b;
          font-size: 20PX;
          opacity: 0.3;
        }

        .service-con {
          width: 100%;
          position: absolute;
          top: 0;
        }

        .line2 {
          width: 69PX;
          height: 3PX;
          border-radius: 3PX;
          background-color: #fe992b;
          margin: 0 auto;
        }

        .servicetop2,.servicetop3 {
          // width: 320px;
          font-size: 20PX;
          color: #333;
          font-weight: bold;
          padding: 10PX 0;
          // border-bottom: 6px solid #fe992b;
          border-radius: 3PX;
          margin: 0 auto;
        }
      }
    }
  }
}
  .zhunbei-sm {
      // padding-top: 40PX;
      .zhunbeimsg{
        // .zhunbeione img{
        //   width:335PX;
        //   height: 500PX;
        // }
        .zhunbeitwo{
          position: relative;
        //     img{
        //   width: 335PX;
        //   height: 571PX;
        // }
         .accordion>div img{
            width: 24PX;
            height: 24PX;
          }
        .zhunbeitwo-text{
          position: absolute;
          top: 140PX;
          left: calc(30PX + 5vw);
          .zhunbeitwo-text-top{
            font-size: 18PX;
            font-weight: bold;
            margin-bottom: 16PX;
          }
        }
        }
       .zhunbeithree{
         position: relative;
         margin-top: 15PX;
        //   img{
        //   width: 335PX;
        //   height: 329PX;
        // }
         .accordion2>div img{
            width: 24PX;
            height: 24PX;
          }
       }
      }
      .title {
      position: relative;
      height: 68PX;

      .english-yellow {
        color: #fe992b;
        font-size: 20PX;
        opacity: 0.3;
      }

      .service-con {
        width: 100%;
        position: absolute;
        top: 0;
      }

      .line2 {
        width: 69PX;
        height: 3PX;
        border-radius: 3PX;
        background-color: #fe992b;
        margin: 0 auto;
      }

      .servicetop {
        // width: 160px;
        font-size: 20PX;
        color: #333;
        font-weight: bold;
        padding: 10PX 0;
        // border-bottom: 6PX solid #fe992b;
        border-radius: 3PX;
        margin: 0 auto;
      }

      .servicetop2 {
        width: 320PX;
        font-size: 32PX;
        color: #333;
        font-weight: bold;
        padding: 10PX 0;
        // border-bottom: 6PX solid #fe992b;
        border-radius: 3PX;
        margin: 0 auto;
      }
    }
        .accordion,.accordion2 {
          padding: 30PX;
            font-size: 14PX;

          .el-collapse-item__content {
            color: #666;
            font-size: 16PX;
          }

          .title2 {
            font-size: 18PX;
            font-weight: 600;
            img{
              width: 24PX;
              height: 24PX;
              margin-left: 6PX;
              vertical-align: -5PX;
            }
          }

          .el-collapse-item__header {
            border: none;
            margin-bottom: 20PX;
          }

          .el-collapse {
            border: none;
          }

          .el-collapse-item__arrow {
            color: #fe992b;
            font-size: 16PX;
          }

          .el-collapse-item__wrap {
            border: none;
          }
        }
        .card{
          
          min-height: 518PX;
        }
      .card,.card2 {
          text-align: left;
          width:320PX;
          box-shadow: 0px 3PX 6PX 1PX rgba(0, 0, 0, 0.16);
          margin: 0 auto;

          > div:nth-child(1) {
            width: 320PX;
            height: 110PX;
            background-image: url(../../assets/image/846.png);
            font-size: 24PX;
            color: #fff;
            padding: 22PX 30PX 0 30PX;

            > div {
              line-height: 32PX;
            }
          }
        }
      
      
    } 
</style>